<template>
  <div id="UIElement">
    <qrcode-stream @decode="onDecode" @init="onInit"> </qrcode-stream>
    <p class="error">{{ error }}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      error: ''
    }
  },
  methods: {
    async onDecode(decodedString) {
      console.log(decodedString);
      await this.$store.dispatch("brew/getBrew", decodedString) 
      this.$router.push({ name: 'Brew', params: { id: decodedString } })
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  }
};
</script>
<style scoped>
p {
  color: red;
}
</style>
